import './breeze.css';
import '../css/bootstrap.min.css';
import '../css/fontawesome-all.min.css';

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

import '../js/bootstrap';
import $ from 'jquery';

// Import des fichiers JavaScript
import '../js/vendor/popper.min';
import '../js/vendor/bootstrap.min';

import.meta.glob([
  '../img/**',
  '../fonts/**',
]);

(function ($) {
    'use strict';

    // Feather Icon Js
    feather.replace();

    /*UI VARS*/
    let logo = document.querySelector('.logo-04');

    /*  Sticky Header*/
    window.addEventListener('scroll', function () {
      let header = document.querySelectorAll('header');

      header.forEach((headItem) => {
        headItem.classList.toggle('sticky', window.scrollY > 0);
      });
      // it's only for homepage-3
      // window.scrollY > 0
      //   ? logo.setAttribute('src', 'assets/img/logo/logo.png')
      //   : logo.setAttribute('src', 'assets/img/logo/logo04.png');
    });

    /* back to top button */
    var topBtn = $('#to-top');
    topBtn.on('click', function (e) {
      e.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, '600');
    });

    /* search box open */
    $('.search-bar').on('click', function () {
      $('.search-box').addClass('search-open');
    });
    $('.search-close').on('click', function () {
      $('.search-box').removeClass('search-open');
    });

    /* humberger menu */
    function toggleSidebar() {
      $('header aside').toggleClass('active');
      $('.hamburger-menu').toggleClass('open');

      var sidebarOpen = $('header aside').hasClass('active');
      if (sidebarOpen) {
        disableScrolling();
      } else {
        enableScrolling();
      }
    }

    $('.hamburger-menu').on('click', function () {
      toggleSidebar();
    });

    $('.close-sidebar').on('click', function () {
      toggleSidebar();
    });

    $('aside .overlay').on('click', function () {
      toggleSidebar();
    });
  })(jQuery);

  function enableScrolling() {
    throw new Error('Function not implemented.');
  }
